import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  overflow: hidden;

  video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
`;

export const LoadingText = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  font-size: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Varsity Team Bold" !important;
  color: #fff;
`